import { filter, find, get, isEmpty, map } from 'lodash';
import styled from 'styled-components';
import React, { useState } from 'react';

import { Select, Table, TBody, TR, TH, TD, Box, Row, H2 } from '@commonsku/styles';
import { getPriceQtyCols } from './utils';
import { formatMoney } from '../../utils';

const PriceRows = ({ label, qty_cols, price }) => {
  const { type, setup, description, price_breaks } = price;
  return <>
    <TR>
      <TH>{label}{isEmpty(type) ? '' : ` - ${type}`}:</TH>
      <TD/>
      {map(qty_cols, (qty, k) => {
        return <TD key={k}/>;
      })}
    </TR>
    <TR>
      <TD>{description}</TD>
      <TD>{setup}</TD>
      {map(qty_cols, (qty, i) => {
        const price_break = find(price_breaks, {qty});
        return <TD key={i}>{price_break ? formatMoney(price_break.price) : ''}</TD>;
      })}
    </TR>
  </>;
};

const StyledTable = styled(Table)`
  &&& {
    &, tbody {
      border: none;
    }
  
    th {
      background: #FFF;
    }
  }
`;

const ProductPricing = ({ product: p }) => {
  const { product } = p || {};
  const price_groups = get(product, 'prices.price_groups');
  const qty_cols = getPriceQtyCols(price_groups);
  const [priceGroupKey, setPriceGroupKey] = useState(get(price_groups, '0.base_price.key') || '');

  const priceGroupOption = map(price_groups, ({ base_price }) => {
    const { key, type, description } = base_price;
    return { label: `${type} - ${description}`, value: key };
  });
  const headerStyle = { fontWeight: 'bold', padding: '25px 0' };
  return <Box>
    <Row><H2>Pricing</H2></Row>
    <StyledTable>
      <TBody>
        <TR style={{ borderBottom: '1px solid #F1F1F1' }}>
          <TD>
            <Select
              placeholder="Select price group"
              value={find(priceGroupOption, { value: priceGroupKey })}
              options={priceGroupOption}
              onChange={({ value }) => {
                setPriceGroupKey(value);
              }}
            />
          </TD>
          <TD style={headerStyle}>setup</TD>
          {map(qty_cols, (qty, j) => {
            return <TD key={j} style={headerStyle}>{qty}</TD>;
          })}
        </TR>
      </TBody>
      {map(
        filter(price_groups, ({ base_price }) => {
          return base_price.key === priceGroupKey;
        }),
        ({ additions, base_price }, i) => {
          return <TBody key={i}>
            <PriceRows label="Base Price" qty_cols={qty_cols} price={base_price}/>
            {map(additions, (price, j) => {
              return <PriceRows key={j} label="Additions" qty_cols={qty_cols} price={price}/>;
            })}
          </TBody>;
        }
      )}
    </StyledTable>
  </Box>;
};

export default ProductPricing;
