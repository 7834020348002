import React from 'react';
import { useSelector } from 'react-redux';

import { getPopups } from '../selectors';
import { useSize } from '../hooks/useSize';
import Header from './Header';
import MainSection from './MainSection';
import ErrorBoundary from '../components/ErrorBoundary';
import Overlay from '../components/Overlay';
import ProductPage from '../components/promo-data/ProductPage';

const PromoDataProductApp = ({ ext_product_id }) => {
  const popups = useSelector(getPopups);
  const [ref, size] = useSize();

  return <ErrorBoundary>
    <Header headerContainerRef={ref}/>
    <MainSection popups={popups} style={{
      paddingTop: size.offsetHeight, marginTop: -size.offsetHeight,
      margin: 0, height: '100%', width: '100%', background: '#EDF2F5'
    }}>
      <div className="main-content" style={{ height: '100%', margin: 0, paddingRight: '1rem', paddingTop: '1rem' }}>
        <ProductPage product_id={ext_product_id}/>
      </div>
    </MainSection>
    <Overlay popups={popups} />
  </ErrorBoundary>;
};

export default PromoDataProductApp;
