import styled from 'styled-components';
import React from 'react';

import { Box, Loading } from '@commonsku/styles';
import { useProduct } from './hooks';
import ProductDetail from './ProductDetail';
import ProductPricing from './ProductPricing';
import ProductColors from './ProductColors';

const StyledContainer = styled.div`
  &&& {
    >div {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      margin-bottom: 1rem;
    }
  }
`;

const ProductPage = ({ product_id }) => {
  const { product, loading, error } = useProduct(product_id);

  if (loading) return <Loading/>;

  return error ? <Box>{error.message}</Box> : <StyledContainer>
    <ProductDetail product={product}/>
    <ProductPricing product={product}/>
    <ProductColors product={product}/>
  </StyledContainer>;
};

export default ProductPage;
