import { get, map, find } from 'lodash';
import styled from 'styled-components';
import React from 'react';

import { THead, Table, TBody, TR, TH, TD, Box, Row, H2 } from '@commonsku/styles';

const StyledTable = styled(Table)`
  &&& {
    &, tbody, thead {
      border: none;
    }
  
    th {
      background: #FFF;
    }
  }
`;

const ProductColors = ({ product: p }) => {
  const { product } = p || {};

  const color_list = get(product, 'colours.list');
  return <Box>
    <Row><H2>Colors</H2></Row>
    <StyledTable>
      <THead>
        <TR>
          <TH>For</TH>
          <TH>Name</TH>
          <TH>Colors</TH>
          {!find(color_list, 'image') ? null : <TH>Image</TH>}
        </TR>
      </THead>
      <TBody>
        {map(color_list, ({ for: target, name, colours, image }) => {
          return <TR>
            <TD>{target}</TD>
            <TD>{name}</TD>
            <TD>{colours.join(', ')}</TD>
            {!image ? null : <TD><img style={{ objectFit: 'contain', width: 164, height: 64 }} src={image}/></TD>}
          </TR>;
        })}
      </TBody>
    </StyledTable>
  </Box>;
};

export default ProductColors;
