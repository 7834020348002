import styled from 'styled-components';
import { get, map, startCase, uniq, filter } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';

import {
  Table, TBody, TD, TH, TR, Box, Row, H2, colors, CheckmarkIcon, XIcon, IconButton, ArrowIcon, Col,
} from '@commonsku/styles';
import { window } from '../../global';

const formatDate = (time, options) => {
  const { from, to } = {
    from: 'YYYY-MM-DD hh:mm:ss ZZ',
    to: 'DD MMMM YYYY',
    ...options,
  };
  const m = moment(time, from);
  return m.isValid() ? m.format(to) : '';
};

const FlexColumn = ({ style, children }) => {
  return <Col style={{
    display: 'flex', flexDirection: 'column', overflow: 'hidden', overflowWrap: 'anywhere', ...style,
  }}>
    {children}
  </Col>;
};

const BooleanDescription = ({ checked, children }) => {
  const textColor = (checked ? colors.green : colors.navy)['70'];
  return <div style={{
    display: 'flex',
    padding: '8px 15px',
    margin: '0 10px',
    backgroundColor: checked ? colors.green['20'] : colors.navy['10'],
    borderRadius: 200,
    color: textColor,
    fontSize: '0.8rem',
    flex: '0 0 auto',
    justifyContent: 'center',
    alignItems: 'center',
  }}>
    {checked ? <CheckmarkIcon color={textColor}/> : <XIcon color={textColor}/>}
    {children}
  </div>;
};

const StyledTable = styled(Table)`
  &&& {
    &, tbody {
      border: none;
    }
    
    th {
      background: #FFF;
      min-width: 150px
    }
  }
`;

const ProductImages = ({ product: p }) => {
  const { product, overview } = p || {};
  const hero_image = get(overview, 'hero_image', '');
  const [displayImage, setDisplayImage] = useState(hero_image);
  const images = uniq(filter([
    hero_image,
    ...(get(product, 'images') || [])
  ]));
  return <FlexColumn style={{ flex: 1 }}>
    <img src={displayImage}/>
    <Row style={{ flexWrap: 'wrap' }}>
      {map(images, (image, i) => {
        return <img key={i} src={image} style={{ width: '25%' }} onClick={() => {
          setDisplayImage(image);
        }}/>;
      })}
    </Row>
  </FlexColumn>;
};

const ProductDetail = ({ product: p }) => {
  const { product, meta, overview } = p || {};
  const website = get(product, 'supplier_website_page', '');

  return <Box>
    <Row style={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
      <H2 style={{ margin: 0 }}>{get(product, 'name')}</H2>
      <Row style={{ alignItems: 'center' }}>
        <BooleanDescription checked={get(meta, 'can_check_stock', false)}>Can check stock</BooleanDescription>
        {get(meta, 'discontinued', false) ? <BooleanDescription checked={false}>Discontinued</BooleanDescription> : null}
        {!website ? null : <IconButton
          variant="primary" size="medium" Icon={ArrowIcon} iconPosition="left"
          onClick={() => {
            window.open(website, 'blank');
          }}
        >
          View on supplier's website
        </IconButton>}
      </Row>
    </Row>
    <Row>
      <FlexColumn style={{ flex: 3 }}>
        <Row>{get(product, 'description', '')}</Row>
        <StyledTable className="product-details-table">
          <TBody>
            {map({
              product_code: get(overview, 'code'),
              supplier: get(overview, 'supplier'),
              listed: formatDate(get(meta, 'first_listed_at')),
              updated: formatDate(get(meta, 'last_changed_at')),
              prices_updated: formatDate(get(meta, 'prices_changed_at')),
            }, (value, key) => {
              return <TR key={key}>
                <TH>{startCase(key)}:</TH>
                <TD>{value}</TD>
              </TR>;
            })}
            {map(get(product, 'details'), ({ name, detail }, i) => {
              return <TR key={i}>
                <TH>{startCase(name)}:</TH>
                <TD>
                  {map(detail.split('\n'), (line, j) => {
                    return <div key={j}>{line}</div>;
                  })}
                </TD>
              </TR>;
            })}
          </TBody>
        </StyledTable>
      </FlexColumn>
      <ProductImages product={p}/>
    </Row>
  </Box>;
};

export default ProductDetail;